// Node Modules
import React from "react";
import { graphql } from "gatsby"

// Components
import Layout from '../components/Layout/Layout'
import Seo from "../components/Seo"
import ContactForm from '../components/Contact'
import WidgetSideProjects from '../components/WidgetSideProjects'
// import HeroAboutPage from '../components/HeroAboutPage'
import AboutContent from "../components/AboutContent"

const GeneralPage = ({ data, location }) => {

  const page = data.pagedata

  const isAboutLocation = location.pathname.split('/')[2] === "about"

  return (
    <>
      <Layout location={location}>
        <Seo strTitle={page.frontmatter.seotitle} strDescription={page.frontmatter.seometadesc} strImage={page.featuredimageLocal.publicURL} />
        {
          isAboutLocation? 
          <>
            <AboutContent />
          </>
          :
          <section className="container mx-auto py-20 pb-2">
            <div className="xs:px-4 sm:px-4 md:px-10 flex flex-col lg:flex-row">
              <div className="w-full lg:w-2/3 lg:mr-12">
                <div className="cmscontent py-4" dangerouslySetInnerHTML={{ __html: page.html }} />
                {page.frontmatter.contactform && (
                <div className="pt-6">
                <ContactForm />
                </div>
                )}
              </div>
              <div className="w-full lg:w-1/3 mb-16 xs:mt-16">
              <WidgetSideProjects />
              </div>
            </div>
          </section>
        }

        

      </Layout>
    </>
  );
};

export default GeneralPage;

export const query = graphql`
  query($slug: String!) {
    pagedata: markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      html
      featuredimageLocal {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP]
            layout: FULL_WIDTH
          )
        }
        ext
        publicURL
      }
      frontmatter {
        featuredimage
        featuredimagealt
        title
        contactform
        seotitle
        seometadesc
      }
    }
  }
`;
