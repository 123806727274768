import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Components

// Assets

const WidgetSideProjects = () => {
  const { gallerysettings, projects } = useStaticQuery(
    graphql`
          query {
              gallerysettings: file(relativePath: {eq: "settings/projects.md"}) {
                  id
                  childMarkdownRemark {
                    settingprojfeatimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          transformOptions: { fit: COVER, cropFocus: CENTER }
                        )
                      }
                    }
                      frontmatter {
                        settingprojtitle
                        settingprojlnkbtn
                        settingprojfeatimagealt
                      }
                  }
              }
              projects: allMarkdownRemark(
                filter: {fileAbsolutePath: {regex: "/(projects)/.*[.]md$/"}, frontmatter: {production: {eq: true}}}
                limit: 2
                sort: {fields: frontmatter___datepub, order: DESC}
              ) {
                edges {
                  node {
                    fields {
                      slug
                    }
                    id
                    featuredimageLocal {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [AUTO, WEBP]
                          layout: FULL_WIDTH
                        )
                      }
                    }
                    frontmatter {
                      production
                      title
                      datepub
                      featuredimagealt
                    }
                  }
                }
              }
          }
        `
  );
  const settings = gallerysettings.childMarkdownRemark.frontmatter
  const allprojimage = getImage(gallerysettings.childMarkdownRemark.settingprojfeatimageLocal)
  return (
    <>
      <section className="projects lg:sticky top-24">
        <h2 className="text-3xl text-template-greenDark font-light py-4 text-center lg:text-right">{settings.settingprojtitle}</h2>
        <div className="grid grid-cols-1 gap-2">
          {projects.edges.map(edge => {
            const frontmatter = edge.node.frontmatter
            const image = getImage(edge.node.featuredimageLocal)
            return (
              <div className="imgContainer max-h-80 lg:max-h-56" key={edge.node.id} >
                <Link to={`/projects${edge.node.fields.slug}/`}>
                  <div className="grid h-full" >
                    <GatsbyImage image={image} alt={frontmatter.featuredimagealt}
                      className="row-start-1 col-start-1 imgWrapper max-h-64 md:max-h-full"
                      layout="fullWidth"
                      aspectratio={3 / 1}
                      style={{ gridArea: "1 / 1" }}
                    />
                    <div className="titleSide">
                      <h2>{frontmatter.title}</h2>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
          <div className="imgContainer max-h-80 lg:max-h-56" >
            <Link to="/projects/">
              <div className="grid h-full" >
                <GatsbyImage image={allprojimage} alt={settings.settingprojfeatimagealt}
                  className="row-start-1 col-start-1 imgWrapper"
                  layout="fullWidth"
                  aspectratio={3 / 1}
                  style={{ gridArea: "1 / 1" }}
                />
                <div className="titleSide">
                  <h2 className="">{settings.settingprojlnkbtn}</h2>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default WidgetSideProjects