import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";
import HeroAboutPage from './HeroAboutPage';
import ModalGallery from '../components/ModalGallery'

import SimpleReactLightbox from 'simple-react-lightbox'
import { SRLWrapper } from "simple-react-lightbox"

import Button from './Button'



const AboutContent = () => {

    const { aboutsettings } = useStaticQuery(
        graphql`
          query {
            aboutsettings: file(relativePath: {eq: "settings/aboutpage.md"}) {
                id
                childMarkdownRemark {
                  featuredimageLocal {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        formats: [AUTO, WEBP]
                        transformOptions: {fit: COVER, cropFocus: CENTER}
                      )
                    }
                  }
                  frontmatter {
                    featuredimagealt
                    abouttabtitle
                    abouttext
                    aboutimage
                    approachtabtitle
                    approachtext
                    approachimage
                    teamtabtitle
                    teammembers {
                        memberimage
                        membername
                        teammembertext
                      }
                    reviewstabtitle
                    reviewstext
                    reviewsimage
                    aboutgallerytitleenable
                    galleryabout {
                        galleryimage
                        gallerytitle
                        galleryimageLocal {
                            childImageSharp {
                                gatsbyImageData(
                                  placeholder: BLURRED
                                  formats: [AUTO, WEBP]
                                  layout: FULL_WIDTH
                                )
                                fluid(maxWidth: 1536, srcSetBreakpoints: [640, 768, 1024, 1280], quality: 65) {
                                  ...GatsbyImageSharpFluid_withWebp 
                                }
                              }
                              ext
                              publicURL
                              id
                        }
                      }
                  }
                }
              }
          }
        `
    );

    const frontmatter = aboutsettings.childMarkdownRemark.frontmatter;
    const AboutTitle = frontmatter.abouttabtitle
    const AboutUs = frontmatter.abouttext
    const OurApproach = frontmatter.approachtext
    const TeamMembers = frontmatter.teammembers
    const Reviews = frontmatter.reviewstext

    const options = {
        settings: {
            autoplaySpeed: 3000,
            boxShadow: 'none',
            disableKeyboardControls: false,
            disablePanzoom: true,
            disableWheelControls: false,
            hideControlsAfter: 3000,
            lightboxTransitionSpeed: 0.3,
            lightboxTransitionTimingFunction: 'linear',
            overlayColor: 'rgba(30, 30, 30, 0.9)',
            slideAnimationType: 'fade',
            slideSpringValues: [300, 50],
            slideTransitionSpeed: 0.6,
            slideTransitionTimingFunction: 'linear',
            usingPreact: true,
        },
        caption: {
          showCaption: frontmatter.aboutgallerytitleenable
        }
    }


    const [tab, setTab] = useState("AboutUs")

    const handleClick = (tabName) => {
        setTab(tabName);
        setPromptButton(false);
    }

    const tabs = ["AboutUs", "OurApproach", "OurTeam", "Reviews"]

    const [scroll, setScroll] = useState(0)
    const isScroll = () => {
        setScroll(window.pageYOffset);
        setPromptButton(false);
    }
    useEffect(() => {
        window.addEventListener("scroll", isScroll, { passive: true })
        return () => {
            window.removeEventListener("scroll", isScroll, { passive: true })
        }
    }, [])

    let [promptButton, setPromptButton] = useState(false)
    useEffect(() => {
        const timer =
            setTimeout(() => {
                setPromptButton(true);
            }, 4000);
        return () => clearTimeout(timer);
    }, [tab, scroll]);

    const nextClick = () => {
        let currentIndex = tabs.indexOf(tab)
        if (currentIndex === 3) { currentIndex = -1 };
        setTab(tabs[currentIndex + 1]);
        setPromptButton(false);
    };

    useEffect(() => {
        const timer =
            setTimeout(() => {
                nextClick();
            }, 14000);
        return () => clearTimeout(timer);
    });

    return (
        <>
            <HeroAboutPage title={AboutTitle} />
            <div id="main" className="w-full bg-white relative" style={{ paddingTop: "0.2px", paddingBottom: "0.2px" }}>
                <div className="bg-white relative xs:pb-2 sm:py-16">
                    {/* <div className="container mx-auto py-2"> */}
                    <div className="container mx-auto xs:mb-2 sm:my-16 xs:px-4 sm:px-4 md:px-10"><SimpleReactLightbox><SRLWrapper options={options}>
                        {/* ABout Tabs */}
                        <div className={tab === "OurTeam"? "w-full flex relative px-2 pt-3 xs:pb-3 sm:pb-16" : "flex justify-start px-2 pt-3 pr-3 xs:pb-3 sm:pb-16 flex-col-reverse md:flex-row-reverse mr-2 md:mr-0 relative"}>

                            <div className={tab === "OurTeam" ? "hidden" : "relative self-auto flex-1 w-full md:w-1/2 grid mr-4 projectsection"} >
                                <div className="boxShadowRight"></div>
                                <div className={tab === "AboutUs" ? "block relative shadow projectsectionimage" : "hidden"} style={{ width: "100%", height: "100%", backgroundImage: `url(${encodeURI(frontmatter.aboutimage)})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                <div className={tab === "OurApproach" ? "block relative shadow projectsectionimage" : "hidden"} style={{ width: "100%", height: "100%", backgroundImage: `url(${encodeURI(frontmatter.approachimage)})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                <div className={tab === "Reviews" ? "block relative shadow projectsectionimage" : "hidden"} style={{ width: "100%", height: "100%", backgroundImage: `url(${encodeURI(frontmatter.reviewsimage)})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                            </div>
                            <div className={tab === "OurTeam" ? "w-full" : "flex justify-start w-full md:w-1/2 md:mr-6"}>
                                <div className="cmscontent pb-3 md:pb-0 w-full">

                                    {/* Tabs */}
                                    <div className="tab flex flex-row flex-wrap">
                                        <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                                            <button className={tab === "AboutUs" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("AboutUs")}>About Us</button>
                                        </div>
                                        <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                                            <button className={tab === "OurApproach" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("OurApproach")}>Our Approach</button>
                                        </div>
                                        <div className="xs:mr-3 sm:mr-3 lg:mr-5 text-xs sm:text-sm lg:text-base">
                                            <button className={tab === "OurTeam" ? " border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("OurTeam")}>Our Team</button>
                                        </div>
                                        <div className="text-xs sm:text-sm lg:text-base">
                                            <button className={tab === "Reviews" ? "border-b-2 border-template-greenDark pb-1 focus:outline-none" : "border-b-0 pb-1 opacity-60 focus:outline-none"} onClick={() => handleClick("Reviews")}>Reviews</button>
                                        </div>
                                    </div>

                                    {/* Tab Content */}
                                    <div className="my-3">
                                        <div id="AboutUs" className={tab === "AboutUs" ? "block" : "hidden"}>
                                            <ReactMarkdown linkTarget="_blank">
                                                {AboutUs}
                                            </ReactMarkdown>
                                        </div>

                                        <div id="OurApproach" className={tab === "OurApproach" ? "block" : "hidden"}>
                                            <ReactMarkdown linkTarget="_blank">
                                                {OurApproach}
                                            </ReactMarkdown>
                                        </div>

                                        <div id="OurTeam" className={tab === "OurTeam" ? "block w-full" : "hidden"}>
                                            
                                                {/* {OurTeam} */}
                                                {TeamMembers.map((item, index) => {
                                                    return (
                                                        <>
                                                        <div className="flex justify-start px-2 pt-3 pr-3 xs:pb-3 sm:pb-16 flex-col-reverse md:flex-row-reverse mr-2 md:mr-0 relative">
                                                            <div className="relative self-auto flex-1 w-full md:w-1/2 grid mr-4 projectsection">
                                                                <div className="boxShadowRight"></div>
                                                                <div className="block relative shadow projectsectionimage" style={{ width: "100%", height: "100%", backgroundImage: `url(${encodeURI(item.memberimage)})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                            </div>
                                                            <div className="flex justify-start w-full md:w-1/2 md:mr-6">
                                                                <div>
                                                                    <h3>{item.membername}</h3>
                                                                    <ReactMarkdown linkTarget="_blank">
                                                                        {item.teammembertext}
                                                                    </ReactMarkdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </>
                                                    ) 
                                                })
                                            }
                                            
                                        </div>
                                        <div id="Reviews" className={tab === "Reviews" ? "block" : "hidden"}>
                                            <ReactMarkdown linkTarget="_blank">
                                                {Reviews}
                                            </ReactMarkdown>
                                        </div>
                                        <div className={promptButton ? "animate-pulse mt-4" : "mt-4"}>
                                            <Button radius={"0px"} btnStyle={"dark"} onClick={() => nextClick()} fontWeight={400}>Next</Button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                        {/* Gallery */}
                        {frontmatter.galleryabout && (
                            frontmatter.galleryabout.length > 0 && (
                                <div className="sm:mb-16">
                                    <ModalGallery gallery={frontmatter.galleryabout} />
                                </div>
                            ))}
                    </SRLWrapper></SimpleReactLightbox>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutContent;